import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";

export default function ViewClient() {
  const navigate = useNavigate();
  const [cientList, setClientList] = useState();
  const [categry, setCategry] = React.useState("Filter");
  const [catgeorylist, setCatgeorylist] = useState([]);
  const [isLoding, setIsLoding] = useState(false);

  const handleChange = (event) => {
    setCategry(event.target.value);
  };
  const Lougout = () => {
    localStorage.clear();
    navigate("/");
  };
  const Catgeorylist = () => {
    axiosInstance
      .get(API_URLS.catgeorylist)
      .then((res) => {
        setCatgeorylist(res.data.categoryData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Catgeorylist();
  }, []);

  const ClientList = () => {
    setIsLoding(true);
    axiosInstance
      .get(API_URLS.ClientlistbyAdmin, {})
      .then((res) => {
        setClientList(res.data.ClientData);
        !res.data.ValidSession && Lougout();
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  const updatestatus = (id) => {
    axiosInstance
      .post(API_URLS.inactiveCLient, { clientId: id })
      .then((res) => {
        toast.success(res.data.message);
        ClientList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const topdels = (id) => {
    axiosInstance
      .post(API_URLS.TopdealClient, { clientId: id })
      .then((res) => {
        toast.success(res.data.message);
        ClientList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    ClientList();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-5">
        <p className="text-xl font-semibold">Client List</p>
        <span className="flex gap-3">
          <Link
            to="https://restapi.aquaeal.in/public/upload/DemoImage.zip"
            className="bg-[#1BCED1] py-1 px-3 rounded-md font-semibold cursor-pointer"
            target="_blank"
          >
            Sample Image{" "}
          </Link>
          <p
            onClick={() => navigate("/add-clinte")}
            className="bg-[#1BCED1] py-1 px-3 rounded-md font-semibold cursor-pointer"
          >
            Add Client
          </p>
          <FormControl
            className=" px-3 rounded-md font-semibold cursor-pointer"
            variant="standard"
            sx={{ minWidth: 120 }}
          >
            <Select value={categry} onChange={handleChange}>
              <MenuItem value={"Filter"}>Filter</MenuItem>
              {catgeorylist?.map((i) => {
                return <MenuItem value={i.id}>{i.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </span>
      </div>
      <hr />

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-gray-600 my-5 h-[75vh]">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-2 py-3">
                Sr.
              </th>
              <th scope="col" class="px-2 py-3">
                Client Title
              </th>
              <th scope="col" class="px-2 py-3">
                Client Describtion
              </th>
              <th scope="col" class="px-2 py-3">
                Client Logo
              </th>
              <th scope="col" class="px-2 py-3">
                Client Banner Image
              </th>
              <th scope="col" class="px-2 py-3">
                Client Token Name
              </th>
              <th scope="col" class="px-2 py-3">
                Client Token Network
              </th>
              <th scope="col" class="px-2 py-3">
                Client Token Logo
              </th>
              <th scope="col" class="px-2 py-3">
                Status
              </th>

              <th scope="col" class="px-2 py-3">
                Action
              </th>
              <th scope="col" class="px-2 py-3">
                Add Top Deals
              </th>
              {/* <th scope="col" class="px-2 py-3">
                Update
              </th> */}
            </tr>
          </thead>
          {isLoding ? (
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item, index) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {cientList?.map((item, index) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">{index + 1}</td>
                    <td class="px-2 py-4 text-white">{item.nametitle}</td>
                    <Tooltip
                      className="!text-white"
                      title={item.clientDescribtion}
                      placement="right"
                    >
                      <td class="px-2 py-4 text-white ">
                        {item.clientDescribtion?.slice(0, 30)}...
                      </td>
                    </Tooltip>

                    <td class="px-2 py-4 text-white">
                      <img
                        src={item.clientLogoPath}
                        alt=""
                        className="h-10 w-10"
                      />
                    </td>
                    <td class="px-2 py-4 text-white">
                      {" "}
                      <img
                        src={item.bannerLogoPath}
                        alt=""
                        className="h-10 w-10"
                      />
                    </td>
                    <td class="px-2 py-4 text-white">{item.tokenname}</td>
                    <td class="px-2 py-4 text-white">{item.tokenNetwork}</td>
                    <td class="px-2 py-4 text-white">
                      <img
                        src={item.tokenLogoPath}
                        alt=""
                        className="h-10 w-10"
                      />
                    </td>
                    <td class="px-2 py-4 text-white">
                      {item.status === "0" ? (
                        <p style={{ color: "red" }}>Inactive</p>
                      ) : (
                        <p style={{ color: "green" }}>Active</p>
                      )}
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Switch
                        checked={item.status === "0" ? false : true}
                        onClick={() => updatestatus(item.id)}
                      />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Switch
                        checked={item.topDeal === "0" ? false : true}
                        onClick={() => topdels(item.id)}
                      />
                    </td>
                    {/* <td
                      onClick={() => navigate(`/edit-clinte/${item.id}`)}
                      class="px-2 py-4 text-white"
                    >
                      Update
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
