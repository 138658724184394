export const API_URLS = {
  getProjectList: "getProjectList",
  login: "login",
  createNewproject: "createNewproject",
  // createNewtask: "createNewtask",
  createNewtask: "Newtaskaddinproject",
  getTaskData: "getTaskDatabyProjectid",
  DeleteTaskRow: "DeleteTaskRow",
  ProjectChangeStatus: "ProjectChangeStatus",
  getDashboard: "getDashboard",
  // catgeorylist: "catgeorylist",
  Subcatgeorylist: "Subcatgeorylist",
  SubSubcatgeorylist: "SubSubcatgeorylist",
  catgeorylist: "catgeorylist",
  rewardlist: "Rewardlist",
  addClientbyAdmin: "addClientbyAdmin",
  ClientlistbyAdmin: "ClientlistbyAdmin",
  addProjectinclientbyadmin: "addProjectinclientbyadmin",
  getcategorybyclient: "getcategorybyclient",
  getcategoryrewardbyclient: "getcategoryrewardbyclient",
  getProjectlistbyAdmin: "getProjectlistbyAdmin",
  addtaskgroupbyadmin: "addtaskgroupbyadmin",
  gettaskgrouplistbyAdmin: "gettaskgrouplistbyAdmin",
  tasktypelist: "tasktypelist",
  gettasklistbyAdmin: "gettasklistbyAdmin",
  addtaskbyadmin: "addtaskbyadmin",
  gettaskdetailelistbyAdmin: "gettaskdetailelistbyAdmin",
  addtaskdetailbyadmin: "addtaskdetailbyadmin",
  inactiveCLient: "inactiveCLient",
  TopdealClient: "TopdealClient",
  getClientdetailbyAdmin: "getClientdetailbyAdmin",
  EditClientbyAdmin: "EditClientbyAdmin",
  addNewArticle: "addNewArticle",
  getarticle: "getarticle",
  inactivearticle: "inactivearticle",
};
