import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { Skeleton, Switch, Tooltip } from "@mui/material";
import { toast } from "react-toastify";

export default function ViewArticle() {
  const navigate = useNavigate();
  const [cientList, setClientList] = useState();
  const [isLoding, setIsLoding] = useState(false);

  const Lougout = () => {
    localStorage.clear();
    navigate("/");
  };

  const getArticle = () => {
    setIsLoding(true);
    axiosInstance
      .get(API_URLS.getarticle, {})
      .then((res) => {
        setClientList(res.data.articledata);
        !res.data.ValidSession && Lougout();
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  const updatestatus = (id) => {
    axiosInstance
      .post(API_URLS.inactivearticle, { articleId: id })
      .then((res) => {
        toast.success(res.data.message);
        getArticle();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getArticle();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-5">
        <p className="text-xl font-semibold">Client List</p>
        <span className="flex gap-3">
          <p
            onClick={() => navigate("/add-article")}
            className="bg-[#1BCED1] py-1 px-3 rounded-md font-semibold cursor-pointer"
          >
            Add Article
          </p>
        </span>
      </div>
      <hr />

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-gray-600 my-5 h-[75vh]">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-2 py-3">
                Sr.
              </th>
              <th scope="col" class="px-2 py-3">
                Article Title
              </th>
              <th scope="col" class="px-2 py-3">
                Article subtitle
              </th>
              <th scope="col" class="px-2 py-3">
                Article Note
              </th>

              <th scope="col" class="px-2 py-3">
                Article dsc
              </th>
              <th scope="col" class="px-2 py-3">
                Article Image
              </th>
              <th scope="col" class="px-2 py-3">
                Status
              </th>

              <th scope="col" class="px-2 py-3">
                Action
              </th>
            </tr>
          </thead>
          {isLoding ? (
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item, index) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {cientList?.map((item, index) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">{index + 1}</td>
                    <td class="px-2 py-4 text-white">{item.title}</td>
                    <Tooltip
                      className="!text-white"
                      title={item.subtitle}
                      placement="right"
                    >
                      <td class="px-2 py-4 text-white ">
                        {item.subtitle?.slice(0, 30)}...
                      </td>
                    </Tooltip>
                    <Tooltip
                      className="!text-white"
                      title={item.note}
                      placement="right"
                    >
                      <td class="px-2 py-4 text-white ">
                        {item.note?.slice(0, 30)}...
                      </td>
                    </Tooltip>
                    <td class="px-2 py-4 text-white ">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.describtion?.slice(0, 30),
                        }}
                      />
                    </td>

                    <td class="px-2 py-4 text-white">
                      <img src={item.imagePath} alt="" className="h-10 w-10" />
                    </td>

                    <td class="px-2 py-4 text-white">
                      {item.status === "0" ? (
                        <p style={{ color: "red" }}>Inactive</p>
                      ) : (
                        <p style={{ color: "green" }}>Active</p>
                      )}
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Switch
                        checked={item.status === "0" ? false : true}
                        onClick={() => updatestatus(item.id)}
                      />
                    </td>

                    {/* <td
                      onClick={() => navigate(`/edit-clinte/${item.id}`)}
                      class="px-2 py-4 text-white"
                    >
                      Update
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
