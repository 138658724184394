import React from "react";
import Headers from "../Components/Headers";
import { Paper } from "@mui/material";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";

export default function Layout({ theme, setTheme, component }) {
  return (
    <div className="bg-[#2B2F3A]">
      <Headers theme={theme} setTheme={setTheme} />

      <div className="flex  h-[87vh] my-2 ">
        <Sidebar />
        <div className="px-3 w-full h-full">
          <Paper className="w-full h-full !bg-[#3C4452]  ">
            <div className="p-3">{component}</div>
          </Paper>
        </div>
      </div>
      <Footer />
    </div>
  );
}
