import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountData } from "../Mock";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function Sidebar() {
  const pid = localStorage.getItem("pId");
  const [navItem, setNavItem] = useState();
  const [side, setSide] = useState(true);
  const navigate = useNavigate();
  const handleClick = (id) => {
    setNavItem(id);
    localStorage.setItem("pId", id);
  };
  useEffect(() => {
    setNavItem(Number(pid));
  }, []);

  console.log(navItem, "navItem");
  return (
    <div
      className={`${
        side ? "flex flex-col px-2 py-5 w-[12%]" : "flex flex-col px-2 py-5"
      }`}
    >
      <div className="flex justify-end items-end">
        {side ? (
          <ArrowBackIosIcon
            onClick={() => setSide(false)}
            className="text-white cursor-pointer !text-xl"
          />
        ) : (
          <ArrowForwardIosIcon
            onClick={() => setSide(true)}
            className="text-white cursor-pointer !text-xl"
          />
        )}
      </div>

      {accountData?.map((i) => {
        return (
          <span
            onClick={() => {
              navigate(i.link);
              handleClick(i.id);
            }}
            className={`flex gap-2  w-full  rounded-lg items-center ${
              navItem === i.id ? "bg-[#1BCED1]" : " bg-[#1BCED1] bg-opacity-10"
            }  cursor-pointer py-1 my-3 px-3`}
          >
            <img src={i.icon} alt="" className="h-8 w-8" />

            {side && <p className="text-white font-semibold">{i.title}</p>}
          </span>
        );
      })}
    </div>
  );
}
