import routes from "./Router";
import Layout from "./Layout";
import Login from "./Authentication/Login";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useState } from "react";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  const [theme, setTheme] = useState(true);
  const themeMode = createTheme({
    palette: {
      mode: theme ? "dark" : "light",
    },
  });
  return (
    <div className="App ">
      <ThemeProvider theme={themeMode}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            {routes.map((route) => {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  setTheme={setTheme}
                  element={<Layout component={route.element} />}
                />
              );
            })}
          </Routes>

          <ToastContainer />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
