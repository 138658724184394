import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddArticle = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [note, setNote] = useState("");
  const [image, setImage] = useState(null); // Use null for image state

  const validateForm = () => {
    let valid = true;
    const errors = [];

    // Validate title
    if (!title.trim()) {
      errors.push("Title is required");
      valid = false;
    }

    // Validate subtitle
    if (!subtitle.trim()) {
      errors.push("Subtitle is required");
      valid = false;
    }

    // Validate note
    if (!note.trim()) {
      errors.push("Note is required");
      valid = false;
    }

    // Validate image
    if (!image) {
      errors.push("Image is required");
      valid = false;
    } else {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      const { type, size } = image;

      // Check file extension and size
      if (!allowedExtensions.test(image.name)) {
        errors.push("Invalid file type. Only JPG, JPEG, PNG are allowed.");
        valid = false;
      }
      if (size > 5242880) {
        // 5MB limit
        errors.push("File size exceeds 5MB limit.");
        valid = false;
      }

      // Check image dimensions
      const img = new Image();
      img.onload = function () {
        if (this.width !== 1500 || this.height !== 600) {
          errors.push("Image dimensions must be 1500x600 pixels.");
          valid = false;
        }
      };
      img.src = URL.createObjectURL(image);
    }

    if (!valid) {
      toast.warn(errors.join("\n"));
    }

    return valid;
  };

  const AdArticle = () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("subtitle", subtitle);
      formData.append("note", note);
      formData.append("image", image);
      formData.append("describtion", value); // Fixed typo in "description"

      axiosInstance
        .post(API_URLS.addNewArticle, formData)
        .then((res) => {
          toast.success(res.data.message); // Fixed typo in "message"
          navigate("/view-article");
        })
        .catch((err) => {
          toast.error("Failed to add article");
        });
    }
  };

  return (
    <div className="flex flex-col overflow-auto h-[75vh]">
      <div className="flex justify-between items-center w-full my-3">
        <p className="text-xl font-semibold">Add Project</p>
      </div>
      <hr />
      <div className="border border-black py-2 my-2 px-5">
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">Article Title</p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>
            <input
              type="text"
              placeholder="Enter Article Title"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </span>
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">Article Subtitle</p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>

            <input
              type="text"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setSubtitle(e.target.value)}
              value={subtitle}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">Article Note</p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>
            <input
              type="text"
              placeholder="Enter Article Note"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setNote(e.target.value)}
              value={note}
            />
          </span>
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">Article Image</p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>

            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </span>
        </div>

        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full ">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">
                {" "}
                Article Description
              </p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>
            <div>
              <ReactQuill theme="snow" value={value} onChange={setValue} />
            </div>
          </span>
        </div>
        <div className="flex w-full justify-end items-end my-5">
          <p
            onClick={AdArticle}
            className="bg-gray-800 py-2 px-5 rounded-lg cursor-pointer hover:bg-gray-500"
          >
            Add Article
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
