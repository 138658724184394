import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import das from "../../Assets/dashboard.png";
import client from "../../Assets/client.png";
import project from "../../Assets/clipboard.png";
import taskgroup from "../../Assets/daily-tasks.png";
import task from "../../Assets/task.png";

export const accountData = [
  {
    id: 1,
    title: "Dashbaord",
    icon: das,
    link: "/dashbaord",
  },
  {
    id: 2,
    title: "Client",
    icon: client,
    link: "/view-client",
  },
  {
    id: 3,
    title: "Project",
    icon: project,
    link: "/view-project",
  },
  {
    id: 4,
    title: "Task Groups",
    icon: taskgroup,
    link: "/view-task-group",
  },
  {
    id: 5,
    title: "Task ",
    icon: task,
    link: "/view-task",
  },
  {
    id: 6,
    title: "View Article ",
    icon: task,
    link: "/view-article",
  },
];
