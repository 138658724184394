import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddProject() {
  const [clientList, setClientList] = useState([]);
  const [clientid, setClientId] = useState("");
  const [category, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [rewardListData, setRewardList] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectBanner, setProjectBanner] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckboxes([...checkboxes, value]);
    } else {
      setCheckboxes(checkboxes.filter((item) => item !== value));
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const fetchClientList = () => {
    axiosInstance
      .get(API_URLS.ClientlistbyAdmin)
      .then((res) => {
        setClientList(res.data.ClientData);
        !res.data.ValidSession && logout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRewardList = () => {
    if (clientid && categoryId) {
      axiosInstance
        .get(API_URLS.getcategoryrewardbyclient + `/${clientid}/${categoryId}`)
        .then((res) => {
          setRewardList(res.data.rewardByclient);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  console.log(rewardListData, "rewardListData");

  useEffect(() => {
    fetchRewardList();
  }, [categoryId, clientid]);

  const fetchCategoryList = () => {
    if (clientid) {
      axiosInstance
        .get(API_URLS.getcategorybyclient + `/${clientid}`)
        .then((res) => {
          setCategoryList(res.data.CatgeoryByclient);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, [clientid]);

  const validateForm = () => {
    if (!clientid) {
      toast.error("Client is required");
      return false;
    }
    if (!projectTitle) {
      toast.error("Project Title is required");
      return false;
    }
    if (!projectBanner) {
      toast.error("Project Banner is required");
      return false;
    }
    if (!categoryId) {
      toast.error("Project Social Media Type is required");
      return false;
    }
    if (!checkboxes.length) {
      toast.error("At least one Project Reward Category is required");
      return false;
    }
    if (!projectDescription) {
      toast.error("Project Description is required");
      return false;
    }
    return true;
  };

  const validateImageDimensions = (file, height, width, callback) => {
    const img = new Image();
    img.onload = () => {
      if (img.height === height && img.width === width) {
        callback(true);
      } else {
        toast.error(`Image must be ${height}px height and ${width}px width`);
        callback(false);
      }
    };
    img.src = URL.createObjectURL(file);
  };

  const addProject = () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    validateImageDimensions(projectBanner, 500, 500, (isValid) => {
      if (isValid) {
        const formData = new FormData();
        formData.append("clientId", clientid);
        formData.append("category_id", categoryId);
        formData.append("rewardcateId", checkboxes);
        formData.append("projectTitle", projectTitle);
        formData.append("projectDescription", projectDescription);
        formData.append("projectBanner", projectBanner);

        axiosInstance
          .post(API_URLS.addProjectinclientbyadmin, formData)
          .then((res) => {
            toast.success("Project added successfully");
            navigate(`/view-project`);
            setIsLoading(false);
          })
          .catch((err) => {
            toast.error("Failed to add project");
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchClientList();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-3">
        <p className="text-xl font-semibold">Add Project</p>
      </div>
      <hr />
      <div className="border border-black py-2 my-2 px-5">
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">Select Client</p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>

            <select
              onChange={(e) => setClientId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
            >
              <option value="">Select Client</option>
              {clientList?.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.nametitle}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">Project Title</p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>
            <input
              type="text"
              placeholder="Enter Project Title"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setProjectTitle(e.target.value)}
              value={projectTitle}
            />
          </span>
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">
                Project Banner (500px * 500px)
              </p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>

            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setProjectBanner(e.target.files[0])}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">
                {" "}
                Project Social Media Type
              </p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>

            <select
              onChange={(e) => setCategoryId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
            >
              <option value="category">Select Category</option>
              {category?.map((i) => (
                <option key={i.categoryId} value={i.categoryId}>
                  {i.catename}
                </option>
              ))}
            </select>
          </span>
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">
                {" "}
                Select Project Reward Category
              </p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>
            <div className="flex gap-2">
              {rewardListData?.map((reward) => (
                <div key={reward._id} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={reward.id}
                    value={reward.id}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label pl-2"
                    htmlFor={reward.name}
                  >
                    {reward.name}
                  </label>
                </div>
              ))}
            </div>
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-3">
              <p className="my-3 text-white font-semibold">
                {" "}
                Project Description
              </p>
              <p className="my-3 text-red-500 font-semibold">*</p>
            </span>
            <textarea
              type="text"
              placeholder="Enter Project Description"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black h-[150px]"
              onChange={(e) => setProjectDescription(e.target.value)}
              value={projectDescription}
            />
          </span>
        </div>
        <div className="flex w-full justify-end items-end my-5">
          <p
            onClick={addProject}
            className="bg-gray-800 py-2 px-5 rounded-lg cursor-pointer hover:bg-gray-500"
          >
            Add Project
          </p>
        </div>
      </div>
    </div>
  );
}
