import React from "react";
import logo from "../../Assets/logo-icon.png";
import textlogo from "../../Assets/logo-text.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Person2Icon from "@mui/icons-material/Person2";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import LogoutIcon from "@mui/icons-material/Logout";
import { Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Headers({ theme, setTheme }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Lougout = () => {
    localStorage.clear();
    navigate("/");
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex justify-between w-full py-3 px-10 bg-gray-800">
      <div className="flex items-center gap-3 justify-center">
        <img src={logo} alt="" className="h-8 w-8" />
        <img src={textlogo} alt="" className="h-8 " />
      </div>
      <div>
        <AccountCircleIcon
          onClick={handleClick}
          className="text-white !text-3xl cursor-pointer"
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            bgcolor: "white",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "white",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "white",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="flex flex-col w-[300px] h-[300px] bg-white ">
          <div className="flex bg-blue-400 p-2 gap-2">
            <img
              src="https://admin.aquaeal.in/assets/images/users/1.jpg"
              alt=""
              className="h-20 w-20 rounded-full"
            />
            <span className="flex flex-col justify-center items-center">
              <p className="font-bold">Shivam Tiwari</p>
              <p>shivam@gmail.com</p>
            </span>
          </div>
          <div className="flex flex-col text-gray-400 justify-center my-3 font-semibold cursor-pointer">
            <span className="flex gap-5 hover:bg-black pl-5 py-2 my-1">
              <Person2Icon />
              <p>My Profile</p>
            </span>
            <span className="flex gap-5 hover:bg-black pl-5 py-2 my-1">
              <AccountBalanceWalletIcon />
              <p>My Balance</p>
            </span>
            <span className="flex gap-5 hover:bg-black pl-5 py-2 my-1">
              <AttachEmailIcon />
              <p>My Inbox</p>
            </span>
            <hr />
            <span
              onClick={Lougout}
              className="flex gap-5 hover:bg-black pl-5 py-2 my-1"
            >
              <LogoutIcon />
              <p>Logout</p>
            </span>
          </div>
        </div>
      </Menu>
    </div>
  );
}
