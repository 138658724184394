import React from "react";

export default function Dashboard() {
  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center w-full gap-2">
        <div className="bg-[#36BEA6] w-full py-8 items-center justify-center flex">
          Totol Project
        </div>
        <div className="bg-[#009EFB] w-full py-8 items-center justify-center flex">
          Active Project
        </div>
        <div className="bg-[#7460EE] w-full py-8 items-center justify-center flex">
          Active Project
        </div>
      </div>
    </div>
  );
}
