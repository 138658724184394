import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { toast } from "react-toastify";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export default function EditClient() {
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nameTitle, setNameTitle] = useState("");
  const [socialLink, setSocialLink] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenNetwork, setTokenNetwork] = useState("");
  const [clientDescription, setClientDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tokenLogo, setTokenLogo] = useState(null);
  const [bannerLogo, setBannerLogo] = useState(null);
  const [clientLogo, setClientLogo] = useState(null);
  const [clientList, setClientList] = useState([]);
  const { id } = useParams();

  const fetchClientList = async () => {
    try {
      const res = await axiosInstance.get(
        API_URLS.ClientlistbyAdmin + `/${id}`
      );
      setClientList(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchClientList();
    }
  }, [id]);

  useEffect(() => {
    if (clientList?.ClientData) {
      setNameTitle(clientList?.ClientData.nametitle || "");
      setSocialLink(clientList?.ClientData.socaillink || "");
      setTokenName(clientList?.ClientData.tokenname || "");
      setTokenAddress(clientList?.ClientData.tokenAddress || "");
      setTokenNetwork(clientList?.ClientData.tokenNetwork || "");
      setClientDescription(clientList?.ClientData.clientDescribtion || "");
      setCategory(clientList?.clientdetail?.[0].categoryId || "");
      setCheckboxes(clientList?.clientdetail?.[0].rewardcategory_id || "");
      // Set other states based on clientList?.ClientData if necessary
    }
  }, [clientList?.ClientData]);

  console.log(checkboxes, "clientList?.clientdetail.categoryId");

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setCheckboxes((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const fetchCategoryList = async () => {
    try {
      const res = await axiosInstance.get(API_URLS.catgeorylist);
      setCategoryList(res.data.categoryData);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchRewardList = async () => {
    try {
      const res = await axiosInstance.get(API_URLS.rewardlist);
      setRewardList(res.data.rewardData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCategoryList();
    fetchRewardList();
  }, []);

  const validateForm = () => {
    if (!nameTitle) {
      toast.error("Client Title is required");
      return false;
    }
    if (!socialLink) {
      toast.error("Client Social Link is required");
      return false;
    }
    if (!category) {
      toast.error("Category is required");
      return false;
    }
    if (!checkboxes.length) {
      toast.error("At least one Reward Category is required");
      return false;
    }
    if (!clientDescription) {
      toast.error("Client Description is required");
      return false;
    }
    return true;
  };

  const validateImageDimensions = (
    file,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    callback
  ) => {
    const img = new Image();
    if (bannerLogo) {
      img.onload = () => {
        if (
          img.height === minHeight &&
          img.width >= minWidth &&
          img.width <= maxWidth
        ) {
          callback(true);
        } else {
          toast.error(
            `Banner Image must be 800px height and between 1500px and 1800px width`
          );
          callback(false);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleAddClient = () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    validateImageDimensions(bannerLogo, 800, 800, 1500, 1800);

    const formData = new FormData();
    formData.append("EditclientId", id);
    formData.append("nametitle", nameTitle);
    formData.append("socaillink", socialLink);
    formData.append("tokenname", tokenName);
    formData.append("tokenAddress", tokenAddress);
    formData.append("tokenNetwork", tokenNetwork);
    formData.append("clientDescribtion", clientDescription);
    formData.append("category", category);
    formData.append("rewardcategory_id", checkboxes);
    formData.append("tokenLogo", tokenLogo);
    formData.append("bannerLogo", bannerLogo);
    formData.append("clientLogo", clientLogo);

    axiosInstance
      .post(API_URLS.EditClientbyAdmin, formData)
      .then((res) => {
        toast.success("Client added successfully");
        setIsLoading(false);
        navigate("/view-client");
      })
      .catch((err) => {
        toast.error("Failed to add client");
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-3">
        <p className="text-xl font-semibold">Update Client</p>
      </div>
      <hr />
      <div className="border border-black py-2 my-2 px-5">
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Client Title</p>
            <input
              type="text"
              placeholder="Enter Client Title"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setNameTitle(e.target.value)}
              value={nameTitle}
            />
          </span>
          <span className="flex flex-col w-full">
            <span className="flex gap-5 items-center">
              <p className="my-3 text-white font-semibold">
                Client Banner Image *
              </p>
              <img
                src={clientList?.ClientData?.bannerLogoPath}
                alt=""
                className="h-10 w-10 rounded-full"
              />
            </span>

            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setBannerLogo(e.target.files[0])}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <span className="flex gap-5 items-center">
              <p className="my-3 text-white font-semibold">Client Logo *</p>
              <img
                src={clientList?.ClientData?.clientLogoPath}
                alt=""
                className="h-10 w-10 rounded-full"
              />
            </span>

            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setClientLogo(e.target.files[0])}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Social Link *
            </p>
            <input
              type="text"
              placeholder="Enter Client Social Link"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setSocialLink(e.target.value)}
              value={socialLink}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Client Token Name</p>
            <input
              type="text"
              placeholder="Enter Client Token Name"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTokenName(e.target.value)}
              value={tokenName}
            />
          </span>
          <span className="flex flex-col w-full">
            <span className="flex gap-5 items-center">
              <p className="my-3 text-white font-semibold">Client Token Logo</p>
              <img
                src={clientList?.ClientData?.tokenLogoPath}
                alt=""
                className="h-10 w-10 rounded-full"
              />
            </span>
            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTokenLogo(e.target.files[0])}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Token Address
            </p>
            <input
              type="text"
              placeholder="Enter Client Token Address"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTokenAddress(e.target.value)}
              value={tokenAddress}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Token Network
            </p>
            <input
              type="text"
              placeholder="Enter Client Token Network"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTokenNetwork(e.target.value)}
              value={tokenNetwork}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Category *</p>
            <select
              onChange={(e) => setCategory(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              value={category}
            >
              <option value="">Select Category</option>
              {categoryList.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Select Reward Category *
            </p>
            <div className="flex justify-between items-center w-full">
              {rewardList.map((reward) => (
                <div key={reward._id} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={reward._id}
                    value={reward._id}
                    checked={checkboxes.includes(reward._id)}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label pl-2" htmlFor={reward._id}>
                    {reward.name}
                  </label>
                </div>
              ))}
            </div>
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Description *
            </p>
            <textarea
              placeholder="Enter Client Description"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black h-[150px]"
              onChange={(e) => setClientDescription(e.target.value)}
              value={clientDescription}
            />
          </span>
        </div>
        <div className="flex w-full justify-end items-end my-5">
          <button
            onClick={handleAddClient}
            className="bg-gray-800 py-2 px-5 rounded-lg cursor-pointer hover:bg-gray-500"
          >
            Update Client
          </button>
        </div>
      </div>
    </div>
  );
}
