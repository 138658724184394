import Dashboard from "../Components/Dashboard";
import Home from "../Components/Home";
import AddArticle from "../Page/Article/Add";
import ViewArticle from "../Page/Article/View";
import AddClinte from "../Page/Client/Add";
import EditClinte from "../Page/Client/Edit";
import ViewClient from "../Page/Client/View";
import AddProject from "../Page/Project/Add";
import EditProject from "../Page/Project/Edit";
import ViewProject from "../Page/Project/View";
import AddTask from "../Page/Task/Add";
import ViewTask from "../Page/Task/View";
import AddTaskGroup from "../Page/TaskGroup/Add";
import ViewTaskGroup from "../Page/TaskGroup/View";

const routes = [
  {
    id: 1,
    path: "/dashbaord",
    element: <Dashboard />,
  },
  {
    id: 2,
    path: "/view-client",
    element: <ViewClient />,
  },
  {
    id: 3,
    path: "/add-clinte",
    element: <AddClinte />,
  },
  {
    id: 4,
    path: "/add-project",
    element: <AddProject />,
  },
  {
    id: 5,
    path: "/view-project",
    element: <ViewProject />,
  },
  {
    id: 6,
    path: "/add-task-group",
    element: <AddTaskGroup />,
  },
  {
    id: 7,
    path: "/view-task-group",
    element: <ViewTaskGroup />,
  },
  {
    id: 8,
    path: "/add-task",
    element: <AddTask />,
  },
  {
    id: 9,
    path: "/view-task",
    element: <ViewTask />,
  },
  {
    id: 10,
    path: "/edit-clinte/:id",
    element: <EditClinte />,
  },
  {
    id: 10,
    path: "/edit-project/:id",
    element: <EditProject />,
  },
  {
    id: 10,
    path: "/add-article",
    element: <AddArticle />,
  },
  {
    id: 11,
    path: "/view-article",
    element: <ViewArticle />,
  },
];
export default routes;
