import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddTaskGroup() {
  const [clientList, setClientList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [clientId, setClientId] = useState("");
  const [taskGroupTitle, setTaskGroupTitle] = useState("");
  const [taskImage, setTaskImage] = useState(null);
  const [taskGroupDescription, setTaskGroupDescription] = useState("");
  const [endTime, setEndTime] = useState("");
  const [start, setStart] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState("");

  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const fetchClientList = () => {
    axiosInstance
      .get(API_URLS.ClientlistbyAdmin)
      .then((res) => {
        setClientList(res.data.ClientData);
        if (!res.data.ValidSession) logout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchClientList();
  }, []);

  const fetchProjectList = () => {
    if (clientId) {
      axiosInstance
        .get(API_URLS.getProjectlistbyAdmin + `/${clientId}`)
        .then((res) => {
          setProjectList(res.data.ProjectData);
          if (!res.data.ValidSession) logout();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchProjectList();
  }, [clientId]);

  const validateForm = () => {
    if (!clientId) {
      toast.error("Client is required");
      return false;
    }
    if (!projectId) {
      toast.error("Project is required");
      return false;
    }
    if (!taskGroupTitle) {
      toast.error("Group Task Title is required");
      return false;
    }
    if (!taskImage) {
      toast.error("Group Task Image is required");
      return false;
    }
    if (!start) {
      toast.error("Group Task Start Date is required");
      return false;
    }
    if (!endTime) {
      toast.error("Group Task End Date is required");
      return false;
    }
    if (!taskGroupDescription) {
      toast.error("Group Task Description is required");
      return false;
    }
    return true;
  };

  const validateImageDimensions = (file, height, width, callback) => {
    const img = new Image();
    img.onload = () => {
      if (img.height === height && img.width === width) {
        callback(true);
      } else {
        toast.error(`Image must be ${height}px height and ${width}px width`);
        callback(false);
      }
    };
    img.src = URL.createObjectURL(file);
  };

  const addTaskGroup = () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    validateImageDimensions(taskImage, 500, 500, (isValid) => {
      if (isValid) {
        const formData = new FormData();
        formData.append("clientId", clientId);
        formData.append("projectid", projectId);
        formData.append("taskgrouptitle", taskGroupTitle);
        formData.append("taskimage", taskImage);
        formData.append("taskgroupdescribtion", taskGroupDescription);
        formData.append("endtime", endTime);
        formData.append("start", start);

        axiosInstance
          .post(API_URLS.addtaskgroupbyadmin, formData)
          .then((res) => {
            navigate(`/view-task-group`);
            toast.success(res.data.message);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Failed to add task group");
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-3">
        <p className="text-xl font-semibold">Add Client</p>
      </div>
      <hr />
      <div className="border border-black py-2 my-2 px-5">
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Client *</p>
            <select
              onChange={(e) => setClientId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
            >
              <option value="">Select Client</option>
              {clientList?.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.nametitle}
                </option>
              ))}
            </select>
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Project *</p>
            <select
              onChange={(e) => setProjectId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
            >
              <option value="">Select Project</option>
              {projectList?.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.projectTitle}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Group Task Title *</p>
            <input
              type="text"
              placeholder="Enter Group Task Title"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTaskGroupTitle(e.target.value)}
              value={taskGroupTitle}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Group Task Image *</p>
            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setTaskImage(e.target.files[0])}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Group Task Start Date *
            </p>
            <input
              type="date"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setStart(e.target.value)}
              value={start}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Group Task End Date *
            </p>
            <input
              type="date"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black"
              onChange={(e) => setEndTime(e.target.value)}
              value={endTime}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Group Task Description *
            </p>
            <textarea
              placeholder="Enter Group Task Description"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2 bg-opacity-15 bg-black h-[150px]"
              onChange={(e) => setTaskGroupDescription(e.target.value)}
              value={taskGroupDescription}
            />
          </span>
        </div>
        <div className="flex w-full justify-end items-end my-5">
          <button
            onClick={addTaskGroup}
            className="bg-gray-800 py-2 px-5 rounded-lg cursor-pointer hover:bg-gray-500"
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
