import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { toast } from "react-toastify";

export default function AddClinte() {
  const navigate = useNavigate();
  const [catgeorylist, setCatgeorylist] = useState([]);
  const [rewardlist, setRewardlist] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isLoding, setIsLoding] = useState(false);
  const [nameTitle, setNameTitle] = useState("");
  const [socaillink, setSocaillink] = useState("");
  const [tokenname, setTokenname] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenNetwork, setTokenNetwork] = useState("");
  const [clientDescribtion, setClientDescribtion] = useState("");
  const [category, setCategory] = useState("");
  const [tokenLogo, setTokenLogo] = useState("");
  const [bannerLogo, setBannerLogo] = useState("");
  const [clientLogo, setClientLogo] = useState("");
  const [categoryInputs, setCategoryInputs] = useState([
    { id: Date.now(), category: "", checkboxes: [] },
  ]);

  let categoryData = [];
  let checkboxesData = [];

  // Iterate through the array and collect data

  // Output the collected data

  console.log(categoryInputs, "categoryInputs");
  categoryInputs.forEach((item) => {
    categoryData.push(item.category); // Collect category data
    checkboxesData.push(item.checkboxes); // Collect checkboxes data
  });

  console.log("Category data:", categoryData);
  console.log("Checkboxes data:", checkboxesData);

  const handleCheckboxChange = (index, e) => {
    const { value, checked } = e.target;
    setCategoryInputs((prev) => {
      const updatedInputs = [...prev];
      const updatedInput = { ...updatedInputs[index] };
      if (checked) {
        updatedInput.checkboxes = [...updatedInput.checkboxes, value];
      } else {
        updatedInput.checkboxes = updatedInput.checkboxes.filter(
          (item) => item !== value
        );
      }
      updatedInputs[index] = updatedInput;
      return updatedInputs;
    });
  };

  const handleCategoryChange = (index, value) => {
    setCategoryInputs((prev) => {
      const updatedInputs = [...prev];
      updatedInputs[index].category = value;
      return updatedInputs;
    });
  };

  const addCategoryInput = () => {
    setCategoryInputs((prev) => [
      ...prev,
      { id: Date.now(), category: "", checkboxes: [] },
    ]);
  };

  const removeCategoryInput = (index) => {
    setCategoryInputs((prev) => prev.filter((_, i) => i !== index));
  };

  // const handleCheckboxChange = (e) => {
  //   const { value, checked } = e.target;
  //   if (checked) {
  //     setCheckboxes([...checkboxes, value]);
  //   } else {
  //     setCheckboxes(checkboxes.filter((item) => item !== value));
  //   }
  // };

  const Catgeorylist = () => {
    axiosInstance
      .get(API_URLS.catgeorylist)
      .then((res) => {
        setCatgeorylist(res.data.categoryData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Rewardlist = () => {
    axiosInstance
      .get(API_URLS.rewardlist)
      .then((res) => {
        setRewardlist(res.data.rewardData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Catgeorylist();
    Rewardlist();
  }, []);

  const validateForm = () => {
    if (!nameTitle) {
      toast.error("Client Title is required");
      return false;
    }
    if (!bannerLogo) {
      toast.error("Client Banner Image is required");
      return false;
    }
    if (!clientLogo) {
      toast.error("Client Logo is required");
      return false;
    }
    if (!socaillink) {
      toast.error("Client Social Link is required");
      return false;
    }
    if (!categoryData) {
      toast.error("Category is required");
      return false;
    }
    if (!checkboxesData.length) {
      toast.error("At least one Reward Category is required");
      return false;
    }
    if (!clientDescribtion) {
      toast.error("Client Description is required");
      return false;
    }
    return true;
  };

  const validateImageDimensions = (
    file,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    callback
  ) => {
    const img = new Image();
    img.onload = () => {
      if (
        img.height === minHeight &&
        img.width >= minWidth &&
        img.width <= maxWidth
      ) {
        callback(true);
      } else {
        toast.error(
          `Banner Image must be 800px height and between 1500px and 1800px width`
        );
        callback(false);
      }
    };
    img.src = URL.createObjectURL(file);
  };

  const AddClient = () => {
    if (!validateForm()) {
      return;
    }
    setIsLoding(true);
    validateImageDimensions(bannerLogo, 800, 800, 1500, 1800, (isValid) => {
      if (isValid) {
        const formData = new FormData();
        formData.append("nametitle", nameTitle);
        formData.append("socaillink", socaillink);
        formData.append("tokenname", tokenname);
        formData.append("tokenAddress", tokenAddress);
        formData.append("tokenNetwork", tokenNetwork);
        formData.append("clientDescribtion", clientDescribtion);
        formData.append("category", JSON.stringify(categoryData));
        formData.append("rewardcategory_id", JSON.stringify(checkboxesData));
        formData.append("tokenLogo", tokenLogo);
        formData.append("bannerLogo", bannerLogo);
        formData.append("clientLogo", clientLogo);

        axiosInstance
          .post(API_URLS.addClientbyAdmin, formData)
          .then((res) => {
            toast.success("Client added successfully");
            setIsLoding(false);
            navigate("/view-client");
          })
          .catch((err) => {
            toast.error("Failed to add client");
            setIsLoding(false);
          });
      } else {
        setIsLoding(false);
      }
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-3">
        <p className="text-xl font-semibold">Add Client </p>
      </div>
      <hr />
      <div className="border overflow-x-auto border-black py-2 my-2 px-5 h-[75vh]">
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Client Title </p>
            <input
              type="text"
              placeholder="Enter Client Title "
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setNameTitle(e.target.value)}
              value={nameTitle}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Banner Image () *
            </p>
            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setBannerLogo(e.target.files[0])}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Client Logo *</p>
            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setClientLogo(e.target.files[0])}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Social Link *
            </p>
            <input
              type="text"
              placeholder="Enter Client Social Link"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setSocaillink(e.target.value)}
              value={socaillink}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Client Token Name</p>
            <input
              type="text"
              placeholder="Enter Client Token Name"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setTokenname(e.target.value)}
              value={tokenname}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Client Token Logo</p>
            <input
              type="file"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setTokenLogo(e.target.files[0])}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Token Address
            </p>
            <input
              type="text"
              placeholder="Enter Client Token Address"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setTokenAddress(e.target.value)}
              value={tokenAddress}
            />
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Token Network
            </p>
            <input
              type="text"
              placeholder="Enter Client Token Network"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setTokenNetwork(e.target.value)}
              value={tokenNetwork}
            />
          </span>
        </div>
        <div className="py-2  px-5 ">
          {categoryInputs.map((input, index) => (
            <div key={input.id}>
              <div className="flex w-full gap-2">
                <span className="flex flex-col w-full">
                  <p className="my-3 text-white font-semibold">
                    Select Category *
                  </p>
                  <select
                    value={input.category}
                    onChange={(e) =>
                      handleCategoryChange(index, e.target.value)
                    }
                    className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                  >
                    <option value="">Select Category</option>
                    {catgeorylist?.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </span>
                <span className="flex flex-col w-full">
                  <p className="my-3 text-white font-semibold">
                    Select Reward Category*
                  </p>
                  <div className="flex justify-between items-center w-full">
                    {rewardlist?.map((reward) => (
                      <div key={reward._id} className="form-check ">
                        <input
                          type="checkbox"
                          className="form-check-input  "
                          id={reward.id}
                          value={reward.id}
                          onChange={(e) => handleCheckboxChange(index, e)}
                        />
                        <label
                          className="form-check-label pl-2"
                          htmlFor={reward.name}
                        >
                          {reward.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </span>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => removeCategoryInput(index)}
                  className="bg-red-500 text-white py-1 px-3 rounded-lg mt-2 mr-2"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <div className="flex justify-start">
            <button
              type="button"
              onClick={addCategoryInput}
              className="bg-green-500 text-white py-1 px-3 rounded-lg mt-2"
            >
              Add Category Input
            </button>
          </div>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Client Description *
            </p>
            <textarea
              placeholder="Enter Client Description"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black  h-[150px]"
              onChange={(e) => setClientDescribtion(e.target.value)}
              value={clientDescribtion}
            />
          </span>
        </div>
        <div className="flex w-full justify-end items-end my-5">
          <p
            onClick={AddClient}
            className="bg-gray-800 py-2 px-5 rounded-lg cursor-pointer hover:bg-gray-500"
          >
            Add Client
          </p>
        </div>
      </div>
    </div>
  );
}
