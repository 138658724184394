import React, { useState } from "react";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/apiUrls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!userName) newErrors.userName = "Username is required.";
    if (!password) {
      newErrors.password = "Password is required.";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters.";
    }
    return newErrors;
  };

  const handleLogin = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsLoading(true);
    axiosInstance
      .post(API_URLS.login, {
        username: userName,
        pass: password,
      })
      .then((res) => {
        setUserName("");
        setPassword("");
        localStorage.setItem("token", res.data.token);
        navigate("/dashbaord");
        setIsLoading(false);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.warn(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="bg-gray-700 w-full h-screen flex justify-center items-center">
      <div className="flex flex-col p-5 w-[500px] bg-gray-500 h-[400px] rounded-lg shadow-lg">
        <p className="text-white font-bold text-xl my-2">Login</p>
        <hr />
        <div className="flex flex-col w-full gap-3 mt-5">
          <span className="flex flex-col">
            <p className="my-3 text-gray-800 font-bold">Username</p>
            <input
              type="text"
              placeholder="Enter Username"
              className="border border-gray-400 outline-none py-2 rounded-lg pl-2 placeholder-black"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />
            {errors.userName && (
              <p className="text-red-500">{errors.userName}</p>
            )}
          </span>

          <span className="flex flex-col">
            <p className="my-3 text-gray-800 font-bold">Password</p>
            <input
              type="password"
              placeholder="Enter Password"
              className="border border-gray-400 outline-none py-2 rounded-lg pl-2 placeholder-black"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {errors.password && (
              <p className="text-red-500">{errors.password}</p>
            )}
          </span>
          <span
            onClick={handleLogin}
            className="flex justify-center items-center my-5"
          >
            <p className="bg-gray-800 py-2 px-5 rounded-md text-white cursor-pointer hover:bg-gray-700">
              {isLoading ? "Loading..." : "Login"}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}
