import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddTask() {
  const navigate = useNavigate();
  const [cientList, setClientList] = useState();
  const [ptojectList, setProjectList] = useState();
  const [data, setdata] = useState();
  const [clientId, setClientId] = useState();
  const [projectId, setProjectId] = useState();
  const [taskGroupId, setTaskGroupId] = useState();
  const [categryID, setCategryId] = useState();
  const [subcatgeoryId, setSubcatgeoryId] = useState();
  const [subSubcatgeoryId, setSubSubcatgeoryId] = useState();
  const [categryData, setCategryData] = useState();
  const [subcatgeorylistData, setSubcatgeorylist] = useState();
  const [subSubcatgeorylistData, setSubSubcatgeorylist] = useState();
  const [tasktype, setTaskType] = useState();
  const [tasktypeId, setTaskTypeId] = useState();
  const [isLoding, setIsLoding] = useState(false);
  const [taskTitle, setTaskTitle] = useState();
  const [taskpoint, setTaskpoint] = useState();
  const [taskContaint, setTaskContaint] = useState();
  const [taskdescribtion, setTaskdescribtion] = useState();

  const Lougout = () => {
    localStorage.clear();
    navigate("/");
  };

  const ClientList = () => {
    axiosInstance
      .get(API_URLS.ClientlistbyAdmin, {})
      .then((res) => {
        setClientList(res.data.ClientData);
        !res.data.ValidSession && Lougout();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    ClientList();
  }, []);

  const GetProject = () => {
    axiosInstance
      .get(API_URLS.getProjectlistbyAdmin + `/${clientId}`, {})
      .then((res) => {
        setProjectList(res.data.ProjectData);
        !res.data.ValidSession && Lougout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetProject();
  }, [clientId]);

  const GetTaskGroup = () => {
    axiosInstance
      .get(API_URLS.gettaskgrouplistbyAdmin + `/${projectId}`, {})
      .then((res) => {
        setdata(res.data.taskgroupData);
        !res.data.ValidSession && Lougout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetTaskGroup();
  }, [projectId]);

  const Categry = () => {
    axiosInstance
      .get(API_URLS.catgeorylist, {})
      .then((res) => {
        setCategryData(res.data.categoryData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Categry();
  }, []);

  const Subcatgeorylist = () => {
    axiosInstance
      .get(API_URLS.Subcatgeorylist + `/${categryID}`, {})
      .then((res) => {
        setSubcatgeorylist(res.data.projectList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    categryID && Subcatgeorylist();
  }, [categryID]);

  const SubSubcatgeorylist = () => {
    axiosInstance
      .get(API_URLS.SubSubcatgeorylist + `/${subcatgeoryId}`, {})
      .then((res) => {
        setSubSubcatgeorylist(res.data.projectList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    subcatgeoryId && SubSubcatgeorylist();
  }, [subcatgeoryId]);

  const Catgeorylist = () => {
    axiosInstance
      .get(API_URLS.tasktypelist, {})
      .then((res) => {
        setTaskType(res.data.taskTypeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Catgeorylist();
  }, []);
  const validateForm = () => {
    if (!clientId) {
      toast.error("Client is required");
      return false;
    }
    if (!projectId) {
      toast.error("Project is required");
      return false;
    }
    if (!taskGroupId) {
      toast.error("Task group is required");
      return false;
    }
    if (!categryID) {
      toast.error("Category is required");
      return false;
    }
    if (!subcatgeoryId) {
      toast.error("Subcategory is required");
      return false;
    }
    if (!subSubcatgeoryId) {
      toast.error("subSubcatgeory is required");
      return false;
    }
    if (!tasktypeId) {
      toast.error("Task type is required");
      return false;
    }
    if (!taskTitle) {
      toast.error("Task title is required");
      return false;
    }
    if (!taskContaint) {
      toast.error("TaskContaint is required");
      return false;
    }
    if (!taskpoint) {
      toast.error("Task Point is required");
      return false;
    }
    if (!taskdescribtion) {
      toast.error("Task description is required");
      return false;
    }

    return true;
  };

  const AddTask = () => {
    if (!validateForm()) {
      return;
    }
    const formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("projectid", projectId);
    formData.append("taskgroupId", taskGroupId);
    formData.append("category_id", categryID);
    formData.append("subcategory_id", subcatgeoryId);
    formData.append("taskContaint", taskContaint);
    formData.append("taskpoint", taskpoint);
    formData.append("subsubcate_id", subSubcatgeoryId);
    formData.append("taskTitle", taskTitle);
    formData.append("taskdescribtion", taskdescribtion);
    formData.append("tasktypeid", tasktypeId);

    setIsLoding(true);

    axiosInstance
      .post(API_URLS.addtaskbyadmin, formData)
      .then((res) => {
        toast.success(res.data.message);
        setIsLoding(false);
        navigate("/view-task");
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  console.log(categryData, "subSubcatgeorylistData");

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-3">
        <p className="text-xl font-semibold">Add Task </p>
      </div>
      <hr />
      <div className="border border-black py-2 my-2 px-5 ">
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Client *</p>
            <select
              onChange={(e) => setClientId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {cientList?.map((i) => {
                return <option value={i.id}>{i.nametitle}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Projrct *</p>
            <select
              onChange={(e) => setProjectId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {ptojectList?.map((i) => {
                return <option value={i.id}>{i.projectTitle}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Task Group *</p>
            <select
              onChange={(e) => setTaskGroupId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {data?.map((i) => {
                return <option value={i.id}>{i.taskgrouptitle}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Categry *</p>
            <select
              onChange={(e) => setCategryId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {categryData?.map((i) => {
                return <option value={i.id}>{i.name}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Select Sub Categry *
            </p>
            <select
              onChange={(e) => setSubcatgeoryId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {subcatgeorylistData?.map((i) => {
                return <option value={i.id}>{i.name}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">
              Select Sub Sub Categry *
            </p>
            <select
              onChange={(e) => setSubSubcatgeoryId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {subSubcatgeorylistData?.map((i) => {
                return <option value={i.id}>{i.name}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
        </div>
        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Select Task Type *</p>
            <select
              onChange={(e) => setTaskTypeId(e.target.value)}
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
            >
              <option>{"Select"}</option>
              {tasktype?.map((i) => {
                return <option value={i.id}>{i.name}</option>;
              })}
            </select>
            {/* <input type="text" placeholder="Enter Username" /> */}
          </span>
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Task Title *</p>
            <input
              type="text"
              placeholder="Enter Username"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setTaskTitle(e.target.value)}
              value={taskTitle}
            />
          </span>
        </div>
        <div className="flex w-full gap-2">
          {categryID === "1" && (
            <>
              {subcatgeoryId === "9" && subSubcatgeoryId === "12" && (
                <span className="flex flex-col w-full">
                  <p className="my-3 text-white font-semibold">
                    {" "}
                    Enter Guild ID (Discord) *
                  </p>
                  <input
                    type="text"
                    placeholder=" Enter Guild ID (Discord)"
                    className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                    onChange={(e) => setTaskContaint(e.target.value)}
                    value={taskContaint}
                  />
                </span>
              )}
              {subcatgeoryId === "8" && subSubcatgeoryId === "11" && (
                <span className="flex flex-col w-full">
                  <p className="my-3 text-white font-semibold">
                    {" "}
                    Enter Username (Telegram) *
                  </p>
                  <input
                    type="text"
                    placeholder=" Enter Username (Telegram)"
                    className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                    onChange={(e) => setTaskContaint(e.target.value)}
                    value={taskContaint}
                  />
                </span>
              )}
              {/* {subcatgeoryId === "6" && (
                <span className="flex flex-col w-full">
                  <p className="my-3 text-white font-semibold">Task Content</p>
                  <input
                    type="text"
                    placeholder="Enter Username"
                    className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                    onChange={(e) => setTaskContaint(e.target.value)}
                    value={taskContaint}
                  />
                </span>
              )} */}
              {subcatgeoryId === "5" && (
                <>
                  {subSubcatgeoryId === "8" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Enter Video Link *
                      </p>
                      <input
                        type="text"
                        placeholder="Enter Video Link"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                  {subSubcatgeoryId === "7" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Enter Video Id *
                      </p>
                      <input
                        type="text"
                        placeholder=" Enter Video Id"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                  {subSubcatgeoryId === "6" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Enter Channel Id *
                      </p>
                      <input
                        type="text"
                        placeholder="Enter Channel Id"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                </>
              )}
              {/* {subcatgeoryId === "2" && (
                <span className="flex flex-col w-full">
                  <p className="my-3 text-white font-semibold">Task Content</p>
                  <input
                    type="text"
                    placeholder="Enter Username"
                    className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                    onChange={(e) => setTaskContaint(e.target.value)}
                    value={taskContaint}
                  />
                </span>
              )} */}
              {subcatgeoryId === "1" && (
                <>
                  {subSubcatgeoryId === "9" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Enter Tweet Id (1793684686377767042) *
                      </p>
                      <input
                        type="text"
                        placeholder="Enter Tweet id:1793684686377767042"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                  {subSubcatgeoryId === "3" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Enter User Name (@username) *
                      </p>
                      <input
                        type="text"
                        placeholder="Enter User Name :@username"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                  {subSubcatgeoryId === "2" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Enter Tweet Id (1793684686377767042) *
                      </p>
                      <input
                        type="text"
                        placeholder="Enter Tweet id:1793684686377767042"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                  {subSubcatgeoryId === "1" && (
                    <span className="flex flex-col w-full">
                      <p className="my-3 text-white font-semibold">
                        Something Write on Tweet *
                      </p>
                      <textarea
                        type="text"
                        placeholder="Something Write on Tweet"
                        className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                        onChange={(e) => setTaskContaint(e.target.value)}
                        value={taskContaint}
                      />
                    </span>
                  )}
                </>
              )}
            </>
          )}
          {categryID === "3" && (
            <>
              <span className="flex flex-col w-full">
                <p className="my-3 text-white font-semibold">Enter Link *</p>
                <input
                  type="text"
                  placeholder="Enter Link"
                  className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
                  onChange={(e) => setTaskContaint(e.target.value)}
                  value={taskContaint}
                />
              </span>
            </>
          )}

          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold">Point On task *</p>
            <input
              type="text"
              placeholder="Enter Client Social Link"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black"
              onChange={(e) => setTaskpoint(e.target.value)}
              value={taskpoint}
            />
          </span>
        </div>

        <div className="flex w-full gap-2">
          <span className="flex flex-col w-full">
            <p className="my-3 text-white font-semibold"> Task description *</p>
            <textarea
              type="text"
              placeholder="Enter Client Description"
              className="border border-gray-400 outline-none py-1 rounded-lg pl-2  bg-opacity-15 bg-black  h-[150px]"
              onChange={(e) => setTaskdescribtion(e.target.value)}
              value={taskdescribtion}
            />
          </span>
        </div>
        <div className="flex w-full justify-end items-end my-5">
          <p
            onClick={AddTask}
            className="bg-gray-800 py-2 px-5 rounded-lg cursor-pointer hover:bg-gray-500"
          >
            Add Task
          </p>
        </div>
      </div>
    </div>
  );
}
