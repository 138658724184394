import {
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";

export default function ViewProject() {
  const navigate = useNavigate();
  const [client, setClient] = useState("");
  const [cientList, setClientList] = useState();
  const [ptojectList, setProjectList] = useState();
  const [isLoding, setIsLoding] = useState(false);

  const handleChange = (event) => {
    setClient(event.target.value);
  };
  const Lougout = () => {
    localStorage.clear();
    navigate("/");
  };
  const ClientList = () => {
    axiosInstance
      .get(API_URLS.ClientlistbyAdmin, {})
      .then((res) => {
        setClientList(res.data.ClientData);
        !res.data.ValidSession && Lougout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetProject = () => {
    setIsLoding(true);
    axiosInstance
      .get(API_URLS.getProjectlistbyAdmin + `/${client}`, {})
      .then((res) => {
        setProjectList(res.data.ProjectData);
        !res.data.ValidSession && Lougout();
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  useEffect(() => {
    ClientList();
  }, []);
  useEffect(() => {
    GetProject();
  }, [client]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-5">
        <p className="text-xl font-semibold">Project List</p>
        <span className="flex gap-3">
          <FormControl
            className=" px-3 rounded-md font-semibold cursor-pointer"
            variant="standard"
            sx={{ minWidth: 120 }}
          >
            <Select value={client} onChange={handleChange}>
              <MenuItem value={""}>Filter</MenuItem>
              {cientList?.map((i) => {
                return <MenuItem value={i.id}>{i.nametitle}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <p
            onClick={() => navigate("/add-project")}
            className="bg-[#1BCED1] py-1 px-3 rounded-md font-semibold cursor-pointer"
          >
            Add Project
          </p>
        </span>
      </div>
      <hr />

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-gray-600 my-5 h-[75vh]">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-2 py-3">
                Sr.
              </th>
              <th scope="col" class="px-2 py-3">
                Project Title
              </th>
              <th scope="col" class="px-2 py-3">
                Project Describtion
              </th>
              <th scope="col" class="px-2 py-3">
                Project Image
              </th>
              <th scope="col" class="px-2 py-3">
                Reward Type
              </th>
              {/* <th scope="col" class="px-2 py-3">
                Update
              </th> */}
            </tr>
          </thead>
          {isLoding ? (
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item, index) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {ptojectList?.map((i, ind) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">{ind + 1}</td>
                    <td class="px-2 py-4 text-white">{i.projectTitle}</td>
                    <Tooltip
                      className="!text-white"
                      title={i.projectDescription}
                      placement="top"
                    >
                      <td class="px-2 py-4 text-white ">
                        {i.projectDescription?.slice(0, 30)}...
                      </td>
                    </Tooltip>
                    <td class="px-2 py-4 text-white">
                      {" "}
                      <img
                        src={i.projectBannerPath}
                        alt=""
                        className="h-10 w-10"
                      />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <div className="flex gap-2">
                        {i.RewardName.map((i) => {
                          return (
                            <p className="bg-gray-400 bg-opacity-40 rounded-xl px-2 text-sm">
                              {i.name}
                            </p>
                          );
                        })}
                      </div>
                    </td>
                    {/* <td
                      onClick={() => navigate(`/edit-project/${i.id}`)}
                      class="px-2 py-4 text-white "
                    >
                      Update
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
