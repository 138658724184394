import axios from "axios";

export const baseURL1 = "https://restapi.aquaeal.in/AdminApi/";

const axiosInstance = axios.create({
  baseURL: baseURL1,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = {
      ...config.headers,
      "Content-Type": "application/x-www-form-urlencoded",
      "X-API-KEY": "MOC!#321",
      "Auth-Token": `${token}`,
    };
    return config;
  },
  (err) => Promise.reject(err)
);

export default axiosInstance;
