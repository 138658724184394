import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/apiUrls";
import {
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";

export default function ViewTask() {
  const [projectId, setProjectID] = useState("");
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [taskgrup, setTaskgroup] = useState();
  const [isLoding, setIsLoding] = useState(false);

  const handleChange = (event) => {
    setProjectID(event.target.value);
  };
  const Lougout = () => {
    localStorage.clear();
    navigate("/");
  };
  const GetTaskGroup = () => {
    axiosInstance
      .get(API_URLS.gettaskgrouplistbyAdmin, {})
      .then((res) => {
        setTaskgroup(res.data.taskgroupData);
        !res.data.ValidSession && Lougout();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetTaskGroup();
  }, []);

  const GetTaskList = () => {
    setIsLoding(true);
    axiosInstance
      .get(API_URLS.gettasklistbyAdmin + `/${projectId}`, {})
      .then((res) => {
        setdata(res.data.taskData);
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoding(false);
      });
  };

  useEffect(() => {
    GetTaskList();
  }, [projectId]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full my-5">
        <p className="text-xl font-semibold">Task List</p>
        <span className="flex gap-3">
          <FormControl
            className=" px-3 rounded-md font-semibold cursor-pointer"
            variant="standard"
            sx={{ minWidth: 120 }}
          >
            <Select value={projectId} onChange={handleChange}>
              <MenuItem value={""}>Filter</MenuItem>
              {taskgrup?.map((i) => {
                return <MenuItem value={i.id}>{i.taskgrouptitle}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <p
            onClick={() => navigate("/add-task")}
            className="bg-[#1BCED1] py-1 px-3 rounded-md font-semibold cursor-pointer"
          >
            Add Task
          </p>
        </span>
      </div>
      <hr />

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-gray-600 my-5 h-[75vh]">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-2 py-3">
                Sr.
              </th>
              <th scope="col" class="px-2 py-3">
                Task Title
              </th>
              <th scope="col" class="px-2 py-3">
                Task Describtion
              </th>
              <th scope="col" class="px-2 py-3">
                Task Category
              </th>
              <th scope="col" class="px-2 py-3">
                Task Sub Category
              </th>
              <th scope="col" class="px-2 py-3">
                Task Sub Sub Category
              </th>
              <th scope="col" class="px-2 py-3">
                Task Containt
              </th>
            </tr>
          </thead>
          {isLoding ? (
            <tbody>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item, index) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>

                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                    <td class="px-2 py-4 text-white">
                      <Skeleton animation="wave" />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {data?.map((i, ind) => {
                return (
                  <tr class="bg-gray-600 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-500 dark:hover:bg-gray-600">
                    <td class="px-2 py-4 text-white">{ind + 1}</td>
                    <td class="px-2 py-4 text-white">{i.taskTitle}</td>
                    <Tooltip
                      className="!text-white"
                      title={i.taskdescribtion}
                      placement="right"
                    >
                      <td class="px-2 py-4 text-white ">
                        {i.taskdescribtion?.slice(0, 30)}...
                      </td>
                    </Tooltip>
                    <td class="px-2 py-4 text-white">{i.categoryName}</td>
                    <td class="px-2 py-4 text-white">{i.subcategoryName}</td>
                    <td class="px-2 py-4 text-white">{i.subsubcatename}</td>

                    {/* <td class="px-2 py-4 text-white">{i.taskContaint}</td> */}
                    <Tooltip
                      className="!text-white"
                      title={i.taskContaint}
                      placement="right"
                    >
                      <td class="px-2 py-4 text-white ">
                        {i.taskContaint?.slice(0, 30)}...
                      </td>
                    </Tooltip>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
